import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom'
const QuickGlancefourthslide = () => {
  const options = {
    nav: false,
    items:1,
    autoplay: true,
    smartSpeed: 1000,
     };
  return (
    <>
      <OwlCarousel className="owl-carousel" {...options}>
        <div className="item">
        <div className="draw-border">
        <h3><Link to="CommunityServiceSocialWork">Community Service and Social Work</Link></h3>
        <p>Students, Parents, and Staff of LVIS,NE enthusiastically participated in the Tree Plantation Drive held on 20 July 2019 on the field opposite to the school main gate. The students brought their own saplings and a total of 200 saplings were planted. Each sapling planted was adopted by the students and staff who pledged to nurture it. The objective of this was to sensitise students and create awareness about protecting the environment.</p>
        <div className="clr"></div>
      </div>
        </div>
        <div className="item">
           <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/commuity.jpg" className="image" alt="Lotus Valley International School, Greater Noida"/>
        </div>
      </OwlCarousel>
    </>
  );
};

export default QuickGlancefourthslide;
