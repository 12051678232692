import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom'
const QuickGlancefirstslide = () => {
  const options = {
    nav: false,
    items:1,
    autoplay: true,
    smartSpeed: 1000,
     };
  return (
    <>
      <OwlCarousel className="owl-carousel" {...options}>
        <div className="item">
          <img
            src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/school.jpg"
            className="image img-fluid"
            alt="Lotus Valley International School, Greater Noida"
          />
        </div>
        <div className="item">
          <div className="draw-border">
            <h3>
              <Link to="SchoolTransport">Transport </Link>
            </h3>
            <p>
              Lotus Valley International School, Greater Noida West (Noida Ext)
              is a part of prestigious Lotus Valley International chain of
              schools, committed to high quality education and ranked among the
              top 10 schools in the country.
            </p>
          </div>
        </div>
      </OwlCarousel>
    </>
  );
};

export default QuickGlancefirstslide;
