import Home from'./Pages/Home'
import './Assets/css/Style.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import GeneralInfo from'./Pages/GeneralInfo'
import MissionVision from'./Pages/MissionVision'
import OurEmblem from'./Pages/OurEmblem'
import OurFaculty from'./Pages/OurFaculty'
import AdvisoryMembers from'./Pages/AdvisoryMembers'
import OurPatron from'./Pages/OurPatron'
import PrincipalDesk from'./Pages/PrincipalDesk'
import OurMotto from'./Pages/OurMotto'
import AdmissionProcess from'./Pages/AdmissionProcess'
import ApplicationForm from'./Pages/ApplicationForm'
import ArtCraft from'./Pages/ArtCraft'
import Assemblies from'./Pages/Assemblies'
import AssessmentEvaluation from'./Pages/AssessmentEvaluation'
import Booklist from'./Pages/Booklist'
import Circulars from'./Pages/Circulars'
import CommitteeDetails from'./Pages/CommitteeDetails'
import CommunityServiceSocialWork from'./Pages/CommunityServiceSocialWork'
import Contact from'./Pages/Contact'
import CorePhilosophy from'./Pages/CorePhilosophy'
import Curriculum from'./Pages/Curriculum'
import Dance from'./Pages/Dance'
import ExperientialLearning from'./Pages/ExperientialLearning'
import FeeStructure from'./Pages/FeeStructure'
import HobbyClubs from'./Pages/HobbyClubs'
import Music from'./Pages/Music'
import ListHolidays from'./Pages/ListHolidays'
import JobOpportunities from'./Pages/JobOpportunities'
import ITELearning from'./Pages/ITELearning'
import Sports from'./Pages/Sports'
import TeachingMethodology from'./Pages/TeachingMethodology'
import SubjectShowcases from'./Pages/SubjectShowcases'
import StudentStrength from'./Pages/StudentStrength'
import Infrastructure from'./Pages/Infrastructure'
import Tc from'./Pages/Tc'
import Testimonial from'./Pages/Testimonial'
import SchoolTransport from'./Pages/SchoolTransport'
import SchoolTimings from'./Pages/SchoolTimings'
import ParentPortal from'./Pages/ParentPortal'
import ERPSoftware from'./Pages/ERPSoftware'
import TrainingTrainers from'./Pages/TrainingTrainers'
import OurAchievements from'./Pages/OurAchievements'
import PhotoGallery from'./Pages/PhotoGallery' 
import News from'./Pages/News'
import Media from'./Pages/Media'
import { BrowserRouter,Routes,Route } from "react-router-dom";
import SubGallery from'./Pages/SubGallery' 
import Events from'./Pages/Events'
import StudentExchangeProgramm from'./Pages/studentExchangeProgramm'
import TopperX from'./Pages/topperX'
import TopperXII from'./Pages/topperXII'
import Result from'./Pages/result'
function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
         <Route exact path="/" element={<Home/>}/>
         <Route path="GeneralInfo" element={<GeneralInfo/>}/> 
         <Route path="MissionVision" element={<MissionVision/>}/> 
         <Route path="OurEmblem" element={<OurEmblem/>}/> 
         <Route path="OurFaculty" element={<OurFaculty/>}/> 
         <Route path="AdvisoryMembers" element={<AdvisoryMembers/>}/> 
         <Route path="OurPatron" element={<OurPatron/>}/> 
         <Route path="PrincipalDesk" element={<PrincipalDesk/>}/> 
         <Route path="OurMotto" element={<OurMotto/>}/> 
         <Route path="AdmissionProcess" element={<AdmissionProcess/>}/> 
         <Route path="ApplicationForm" element={<ApplicationForm/>}/> 
         <Route path="ArtCraft" element={<ArtCraft/>}/> 
         <Route path="Assemblies" element={<Assemblies/>}/> 
         <Route path="AssessmentEvaluation" element={<AssessmentEvaluation/>}/> 
         <Route path="Booklist" element={<Booklist/>}/> 
         <Route path="Circulars" element={<Circulars/>}/> 
         <Route path="CommitteeDetails" element={<CommitteeDetails/>}/> 
         <Route path="CommunityServiceSocialWork" element={<CommunityServiceSocialWork/>}/> 
         <Route path="Contact" element={<Contact/>}/> 
         <Route path="CorePhilosophy" element={<CorePhilosophy/>}/> 
         <Route path="Curriculum" element={<Curriculum/>}/> 
         <Route path="Dance" element={<Dance/>}/> 
         <Route path="ExperientialLearning" element={<ExperientialLearning/>}/> 
         <Route path="FeeStructure" element={<FeeStructure/>}/> 
         <Route path="HobbyClubs" element={<HobbyClubs/>}/> 
         <Route path="Music" element={<Music/>}/> 
         <Route path="ListHolidays" element={<ListHolidays/>}/> 
         <Route path="JobOpportunities" element={<JobOpportunities/>}/> 
         <Route path="ITELearning" element={<ITELearning/>}/> 
         <Route path="Sports" element={<Sports/>}/> 
         <Route path="TeachingMethodology" element={<TeachingMethodology/>}/> 
         <Route path="SubjectShowcases" element={<SubjectShowcases/>}/> 
         <Route path="StudentStrength" element={<StudentStrength/>}/> 
         <Route path="Infrastructure" element={<Infrastructure/>}/> 
         <Route path="Tc" element={<Tc/>}/> 
         <Route path="Testimonial" element={<Testimonial/>}/> 
         <Route path="SchoolTransport" element={<SchoolTransport/>}/> 
         <Route path="SchoolTimings" element={<SchoolTimings/>}/> 
         <Route path="ParentPortal" element={<ParentPortal/>}/> 
         <Route path="ERPSoftware" element={<ERPSoftware/>}/> 
         <Route path="TrainingTrainers" element={<TrainingTrainers/>}/> 
         <Route path="OurAchievements" element={<OurAchievements/>}/> 
         <Route path="PhotoGallery" element={<PhotoGallery/>}/> 
         <Route path="SubGallery" element={<SubGallery/>}/> 
         <Route path="News" element={<News/>}/>  
         <Route path="Media" element={<Media/>}/>  
         <Route path="Events" element={<Events/>}/> 
         <Route path="studentExchangeProgramm" element={<StudentExchangeProgramm/>}/> 
         <Route path="topperX" element={<TopperX/>}/> 
         <Route path="topperXII" element={<TopperXII/>}/> 
         <Route path="result" element={<Result/>}/> 
     </Routes>
     </BrowserRouter>
    </>
  );
}

export default App;
