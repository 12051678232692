import React, { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";  
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from '../Component/Footer'
import HeadTag from '../Component/HeadTags'
import {Link } from 'react-router-dom'

const TopperXII = () => {
   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState(); // Default to "All"
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");
   useEffect(() => {
      const currentYear = new Date().getFullYear();
      const Year = `${currentYear - 1}-${currentYear}`
      setSelectedYear(Year)
      console.log("get current Year", Year)
   }, [])
   useEffect(() => {
      const fetchData = async () => {
         const galleryData1 = await getTopper();
         const galleryData = galleryData1.filter((item) => item.class === 'XII');
         setData(galleryData);

         // Extract unique years
         const uniqueYears = Array.from(
            new Set(galleryData.flatMap((item) => {
               if (item.sessionYear) {
                  const yearRange = item.sessionYear;
                  console.log(yearRange)
                  return [yearRange];
               } else {
                  return [];
               }
            }))
         );

         // Generate year ranges
         const uniqueYearRanges = uniqueYears.map(year => {
            const nextYear = year - 1;
            return `${year}`;
         });

         // uniqueYearRanges.sort((a, b) => b - a);
         console.log("Unique Year Ranges:", uniqueYearRanges); // Log uniqueYearRanges
         setYearRanges(uniqueYearRanges);
         setFilteredData(galleryData); // Initialize filteredData with all data
      };
      fetchData();
   }, []);
   useEffect(() => {
      // Filter data based on selected year and search query
      let filteredData = data;
      if (selectedYear !== "All") {
         filteredData = filteredData.filter((item) => {
            if (item.sessionYear) {
               return item.sessionYear === selectedYear;
            } else {
               return false;
            }
         });
      }
      if (searchQuery) {
         filteredData = filteredData.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
         );
      }

      filteredData.sort((a, b) => parseFloat(b.division) - parseFloat(a.division));
      setFilteredData(filteredData);
   }, [selectedYear, searchQuery, data]);

   return (
      <>
         <HeadTag title="Our Achievements" />
         <Highlights />
         <InnerHaeder />

         <div className="inner_page">
            <div className="bgimage">
               <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  Academic <i className="fas fa-angle-right"></i>  Topper XII</span></h5>
               <h2>Topper XII</h2>
            </div>
            <div className="container-fluid flwidth  pt-4">
               <div className="row tabs-dynamic">
                  <div className="col-md-6">
                     <div className="month-selection">
                        <select
                           value={selectedYear}
                           onChange={(e) => setSelectedYear(e.target.value)}
                           className="yearSelection"
                        >
                           <option value={"All"}>All</option>
                           {yearRanges.map((yr) => (
                              <option key={yr} value={yr}>{yr}</option>
                           ))}
                        </select>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="searchBox">
                        <input
                           type="text"
                           id="myInput"
                           name="name"
                           value={searchQuery}
                           onChange={(e) => setSearchQuery(e.target.value)}
                           placeholder="Search Here.."
                        />
                     </div>
                  </div>
               </div>
               <div className="row">
                  {filterData.length > 0 ? (
                     filterData.map((item, index) => (
                        <div className="col-md-3" key={index}>
                           <div className="topper-section services-style-current-theme text-center mb-lg-50 innertopper">
                              <div className="topper-service">
                                 <div className="thumb">
                                    <img className="img-fluid" src={`https://webapi.entab.info/api/image?url=${item.attachments}`} alt="Lotus Valley school, Greater Noida" />
                                 </div>
                                 <div className="details clearfix">
                                    <h3 className="sub_title">  {item?.name} </h3>
                                    <p>{item?.stream}</p>

                                    <p>Marks: {item?.division}</p>

                                 </div>
                              </div>
                           </div>
                        </div>
                     ))) : (<div className="col-md-3">
                        <div className="topper-section services-style-current-theme text-center mb-lg-50 innertopper">
                           <div className="topper-service">
                              <div className="thumb">
                                 <img className="img-fluid" src="images/dummy.jpg" alt="Lotus Valley school, Greater Noida" />
                              </div>
                              <div className="details clearfix">
                                 <h3 className="sub_title"> Student Name </h3>
                                 <p>Stream</p>

                                 <p><b>Marks</b></p>

                              </div>
                           </div>
                        </div>
                     </div>)}
               </div>
            </div>
         </div>
         <Footer />
      </>
   );
};

export default TopperXII;
