import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const TeachingMethodology = () => {
  return (
    <>
    <HeadTag title="Teaching Methodology" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Academics <i className="fas fa-angle-right"></i> Teaching Methodology </span></h5>
        <h2> Teaching Methodology </h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
               
               <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/about.jpg" className="inner_img_right img-fluid"  alt="Lotus Valley International School, Greater Noida"/>
               <p>We at Lotus Valley believe in learning by doing. When more than one method of learning is accessed as in hands-on learning, the information has a better chance of being stored in the memory. The benefit of hands-on learning in our school revolves around multiple intelligences of children. This method tends to stimulate students into participating and eventually absorbing information that we believe they would not get from normal show-me - tell-me method. Sensorial learning method involves the child in a total learning experience which enhances the child's ability to think, connect and apply. </p>
               <p> Sensorial activities involve working with materials and activities that assist in the development of sensory organs. The aim of the sensorial materials is fine-tuning of the sensorial perceptions: visual, tactile, auditory, olfactory, gustatory, thermic, baric, stereognostic, and chromatic. The sensorial activities are designed to bring meaning and order to the wide range of sense impressions that the child accumulates i.e. sight, taste, touch, smell and sound. Subsequently, they play a key role in the development of intelligence, because the sensory organs are the scouts for gathering information.</p>
               <p>Children learn through child receptive teaching aids, assemblies, circle time, audio-visuals, e-learning through electronic interactive boards. </p>
          
            </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default TeachingMethodology
