import React, { useEffect } from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const GeneralInfo = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'General Info - Lotus Valley Greater Noida'; // Change 'Your Website Name' to your actual website name
  }, []);
  return (
    <>
    <HeadTag title="General Information" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
     <div className="container-fluid flwidth">
         <div className="bgimage">
     <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> About Us  <i className="fas fa-angle-right"></i> General Information </span></h5>
     <h2>General Information</h2>
      <div className="clr"></div></div>
      <p><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/about.jpg" className="inner_img img-fluid" alt="Lotus Valley International School, Greater Noida"/></p>
      <p> Lotus Valley International School, Greater Noida West (Noida Ext) is a part of prestigious Lotus Valley International chain of schools, committed to high quality education and ranked among the top 10 schools in the country. </p>
          <p> The school is driven by one of the most accomplished educationists in the country who is not just a great leader, but also a visionary, Dr Shayama Chona, the only school educator who has been awarded the Padma  Shri and Padma Bhushan by the President of India.</p>
          <p>Registrations for admission to grades  Nur till IX and XI have started w.e.f. August 2024. The registration form is available at Lotus Valley International School Noida extension, Plot 20A, Sector-Tech Zone IV, Greater Noida West (Noida Ext.)</p>
          <div className="clr"></div> 
      </div>
     </div>
     <Footer/>
    </>
  )
}

export default GeneralInfo
