import React, { useEffect } from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const JobOpportunities = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Job Opportunities - Lotus Valley Greater Noida'; // Change 'Your Website Name' to your actual website name
  }, []);
  return (
    <>
    <HeadTag title="Job Oportunities" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Careers <i className="fas fa-angle-right"></i>  Job Opportunities</span></h5>
        <h2>Job Opportunities</h2>
        </div>
        
        <iframe src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Vacancy.pdf#toolbar=0&navpanes=0" width="100%" height="800" frameBorder="0"></iframe>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default JobOpportunities
