import React, { useEffect, useState } from "react";
import { getEvents } from "../Service/Api";
import Eventsprops from '../Pages/Eventsprops' 
import Slider from "react-slick";
const HomeEvents = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
     const getData = async () => {
        const datas = await getEvents();
        setData(datas);
     };
     getData();
  }, []);

  const settings = { 
     infinite: false,
     arrows:false,
     dots:false,
     speed: 500,
     slidesToShow: 2,
     margin: 30,
     slidesToScroll: 1,
     initialSlide: 0,
     responsive: [
        {
           breakpoint: 1200,
           settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
           }
        },
        {
           breakpoint: 1000,
           settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
           }
        },
        {
           breakpoint: 700,
           settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
           }
        }
     ]
  };
  return (
    <>
    <Slider {...settings}> 
     {data.length > 0 && data.map((item, index) => (
     <Eventsprops  key={index}  link={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} img={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.images}`} title={item.title} details="View Details"  detailsmonth={item.date} detailshour={item.time} detailslocation={item.location}/>
      ))}
      </Slider>
      {/* <Eventsprops link="#" img="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/events.jpg" title="Lotus Valley International School, Greater Noida West (Noida Ext) is a part of prestigious Lotus Valley International chain of schools, committed to high quality education and ranked among the top 10 schools in the country." 
      details="View Details"  detailsmonth="dd.mm.yyyy" detailshour="HH:MM" detailslocation="School Campus"/> */}
     

    </>
  )
}

export default HomeEvents
