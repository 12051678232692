import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const ListHolidays = () => {
  return (
    <>
    <HeadTag title="List Of Holidays" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Co-Curricular <i className="fas fa-angle-right"></i>  List Of Holidays </span></h5>
        <h2> List Of Holidays</h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12"> 
               <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Holiday-list.jpg" className="art_craft_img img-fluid mx-auto d-block"  alt="Lotus Valley International School, Greater Noida"/>
            </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default ListHolidays
