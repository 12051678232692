import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const ArtCraft = () => {
  return (
    <>
    <HeadTag title="Art & Craft" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Admission <i className="fas fa-angle-right"></i>  Art & Craft </span></h5>
        <h2> Art & Craft</h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
               <h3 className="text-center">"A work of art is above all an adventure of the mind."</h3>  
               <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/art.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/art.jpg" className="art_craft_img img-fluid mx-auto d-block"  alt="Lotus Valley International School, Greater Noida"/></a> 
            </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default ArtCraft
