import React from 'react'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
      <div className="erp_links">
         <Link to="https://www.lviscampuscare.net" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Campulogo.png" className="img-fluid float-end campus" alt="Lotus Valley International School, Greater Noida"/></Link>
      </div>
      </>
        
  )
}

export default Campuscare
