import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const OurPatron = () => {
  return (
    <>
    <HeadTag title="Our Patron" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
     <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> About Us  <i className="fas fa-angle-right"></i> Our Patron </span></h5>
        <h2>Our Patron</h2>
        </div>
        <div className="message_inner">
        <div className="messagebox">
        <p><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/patron.jpg" className="inner_img" alt="Lotus Valley International School, Greater Noida"/></p>
        <p>“Leadership is lifting a person’s vision to high sights, the raising of a person’s performance to a higher standard, the building of a personality beyond its normal limitations.” – <strong>Peter Drucker</strong> </p>
        <p>We are privileged to have as Patron one of the most accomplished educationists in the country who is not just a great leader, but also a visionary, Dr Shayama Chona, the only school educator who has been awarded the Padma Shri and Padma Bhushan by the President of India. </p>
        <p>An alumnus of the prestigious Maharani Gayatri Devi Girls’ Public School, Dr Chona has been instrumental in multiple initiatives for the teaching of Science, Computers and Information Technology in schools, yet another illustration of her propulsive vision for school education. She serves on the Advisory Board of 97 schools, and on the Managing Committee of 68 others, and is largely responsible for altering the landscape of education in India in her career in academia. Dr Chona is also a Member of the Indian National Commission for Cooperation with UNESCO, the governing body of CBSE, the National Mission Authority of the National Mission for Empowerment of Women, and an Honorary Advisor in the Education Department of MCD. </p>
        <p>Dr Chona’s contributions are not limited to the field of scholastics. She has also contributed to the transcendent principles of humanity and helped academic communities develop an inclusive, all-encompassing world vision. Towards this effort, she has set up the Autism Centre – School of Hope, the Tamanna Special Education Centre, and Nai Disha – the Skill Development Centre. She has been committed to the cause of education not just as an end, but as a means to the end of creating an evolved, progressive society that exists to serve. She also serves as Member on the Advisory Board of Coca Cola, India Foundation, Stem Cell Committee of Ganga Ram Hospital and Medanta Independent Ethics Committee.</p>
        <p>She has also found mention in the Limca Book of Records 2007 for being the most decorated educator, including 54 awards, including 2 National Awards and 1 State Award, in addition to the Padma Shri and Padma Bhushan. It is, therefore, our proud privilege to imbibe her vision of a service-centred society strengthened rather than compromised by its pluralism and heterogeneity, a society wherein every child is a potential leader and pioneer, to be nurtured in and by a spirit of enquiring, exploring, discovering and doing. </p>
        <div className="clr"></div>
        </div>
        </div>
        </div>
        </div>
     <Footer/>
    </>
  )
}

export default OurPatron
