import React, { useState } from 'react';
import InnerHaeder from '../Component/InnerHaeder';
import Highlights from '../Component/Highlights';
import Footer from '../Component/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { postTransferCertificate } from '../Service/Api';

import HeadTag from '../Component/HeadTags'
const Tc = () => {
  const navigate = useNavigate();
  const [admission, setAdmission] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const handleAdmissionNoChange = (event) => {
    setAdmission(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleFullNameChange = (event) => {
    setName(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !admission) {
      setError("Please fill in all fields");
      return;
    }

    try {
      const response = await postTransferCertificate(name, admission);
      if (response) {
        console.log(response.data);
        // Potentially navigate or show success message
        fetch(`https://d280nq1n4mqyso.cloudfront.net/api/image/${response.data}`)
          .then(response => response.blob())  // Assuming the data is in a blob format; adjust if different
          .then(blob => {
            // Create a link element, use it to download the blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'tc.jpg';  // Set the file name and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // alert('Your download has started.');  // Optional: Notify the user
          })
          .catch(() => alert('Failed to download data.'));  // Error handling
      }
    } catch (error) {
      console.error("Failed to post data", error);
      setError("Failed to process your request. Please try again later.");
    }
  };

  return (
    <>
    <HeadTag title="Transfer Certificate" />
      <Highlights />
      <InnerHaeder />
      <div className="inner_page">
        <div className="container-fluid flwidth">
          <div className="bgimage">
            <h5>
              <Link to="/"> Home <i className="fas fa-angle-right"></i> </Link>
              <span> Transfer Certificate</span>
            </h5>
            <h2>Transfer Certificate</h2>
          </div>
          <div className="row form_sec">
            <div className="col-lg-6">
              <form onSubmit={handleSubmit} className="tc">
                <div className="text_box_sec">
                  <i className="fas fa-user"></i>
                  <input type="text" placeholder="Student Name" id="name" value={name} onChange={handleFullNameChange} />
                </div>
                <div className="text_box_sec">
                  <i className="fas fa-graduation-cap" aria-hidden="true"></i>
                  <input type="text" placeholder="Admission Number" id="admission" value={admission} onChange={handleAdmissionNoChange} />
                </div>
                <div className="text_box_sec">
                  <input type="submit" name="btnshwtcdetails" value="View Details" id="btnshwtcdetails" className="btn btn-danger" />
                </div>
                {error && <p className="error">{error}</p>}
              </form>
            </div>
            <div className="col-lg-6">
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/TC-format.jpg" className="img-fluid mx-auto d-block" alt="Lotus Valley International School, Greater Noida" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Tc;
