import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from '../Component/Footer'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const CommunityServiceSocialWork = () => {
  return (
    <>
      <HeadTag title="Community Service Social Work" />
      <Highlights />
      <InnerHaeder />
      <div className="inner_page">
        <div className="container-fluid flwidth testi_sec">
          <div className="bgimage">
            <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  Co-Curricular <i className="fas fa-angle-right"></i>  Community Service Social Work</span></h5>
            <h2>Community Service Social Work</h2>
          </div>
          <Tabs className="Tabs">
            <TabList>
              <Tab>2024</Tab>
              <Tab>2023</Tab>
              <Tab>2022</Tab>
              <Tab>2020</Tab>
              <Tab>2019</Tab>
            </TabList>
            <div class="clr20"></div>
            <TabPanel>
             

            





              <h3>Udaan: Building Skills, Bridging Communities</h3>

              <div class="clr"></div>
              <p>#ngo #community #learning #makeadifference #bridgingthegap</p>

              <div class="popup-gallery socialWork mt-4">
                <div class="row">

                  <div class="col-lg-4 col-md-4"><a href="/Images/udaan1.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/udaan1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/udaan2.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/udaan2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/udaan3.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/udaan3.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/udaan4.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/udaan4.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/udaan5.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/udaan5.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/udaan6.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/udaan6.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/udaan7.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/udaan7.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/udaan8.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/udaan8.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>

                </div>
              </div>


              <h3>Har Haath Kitab</h3>

              <div class="clr"></div>
              <p>Lotus Valley International School, Noida Extension, partnered with Inchpaper for the 'Har Haath Kitab' mission, aimed at serving underprivileged individuals with utmost happiness and joy.</p>
              <p>Students from Grade VI-XII enthusiastically donated textbooks, notebooks, and storybooks. Our students not only embraced the ethos of community service but also imbibed invaluable lessons in empathy and generosity. Through active participation, they learned the profound impact of their actions on the lives of others.</p>
              <p>#HarHaathKitab #EducationForAll #TransformingLives #CommunityService</p>

              <div class="popup-gallery socialWork mt-4">
                <div class="row">
                  <div class="col-lg-4 col-md-4"><video width="100%" height="300" controls > <source src="/Images/kitab.mp4" type="video/mp4" /> </video></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/kitab1.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/kitab1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/kitab2.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/kitab2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/kitab3.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/kitab3.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/kitab4.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/kitab4.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/kitab5.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/kitab5.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/kitab6.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/kitab6.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/kitab7.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/kitab7.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>

                </div>
              </div>

            </TabPanel>
            <TabPanel>
             

             <h3>Global Youth Service Day (GYSD)</h3>
             <div class="clr"></div>
             <p>“We make a living by what we get, but we make a life by what we give.” - Winston Churchill</p>
             <p>Lotus Valley International School celebrated the Global Youth Service Day (GYSD) under the aegis of AFS, India on the theme ‘Active Global Citizens’ on 28 th April, 2023. GYSD is a worldwide initiative that celebrates the power of young people to make a positive difference in their communities and to promote community engagement. As a part of this programme, the teachers and students’ volunteers organised a feast for our dear supporting staff of Lotus Valley International School who have always helped us and our students, in the smooth functioning of the school. Students’ volunteers prepared sandwiches, Chickpea salad and a refreshing drink in the school premises and served the food to the supporting staff. This activity enabled the learners to collaborate with each other and develop a sense of civic duty. They learnt team work and leadership abilities which enhanced their commitment to serve others and their ability to contribute to society.</p>
             <div class="popup-gallery socialWork mt-4">
               <div class="row">
                 <div class="col-lg-4 col-md-4"><a href="/Images/GYSD1.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/GYSD1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                 <div class="col-lg-4 col-md-4"><a href="/Images/GYSD2.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/GYSD2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                 <div class="col-lg-4 col-md-4"><a href="/Images/GYSD3.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/GYSD3.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                 <div class="col-lg-4 col-md-4"><a href="/Images/GYSD4.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/GYSD4.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                 <div class="col-lg-4 col-md-4"><a href="/Images/GYSD5.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/GYSD5.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                 <div class="col-lg-4 col-md-4"><a href="/Images/GYSD6.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/GYSD6.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                 <div class="col-lg-4 col-md-4"><a href="/Images/GYSD7.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/GYSD7.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                 <div class="col-lg-4 col-md-4"><a href="/Images/GYSD8.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/GYSD8.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
               </div>
             </div>

           </TabPanel>
            <TabPanel>
            <h3>Labour Day Celebration</h3>
              <div class="clr"></div>
              <p>“All labour that uplifts humanity has dignity and importance, and hence should be undertaken with painstaking excellence.”– Martin Luther King, Jr.</p>
              <p>To celebrate this painstaking effort of the labour force, that work tirelessly, the International Worker’s Day also called as Labour Day, was celebrated at Lotus Valley International School, Noida Extension on <span>7th May 2022</span> with great zeal. Labour Day is a reminder for us that these are the real architects of our society, and they deserve their due respect. We have always believed that it is small acts of kindness that count and we attempt to inculcate that same virtue in our children.</p>
              <p>The students of the Social Service Club celebrated the Labour Day by doing fun filled craft activity as well as arranging a feast for all the supporting staff of the school. They were involved in making ‘Thank You’ cards and badges. </p>
              <p>The teachers briefed the students about Labour Day and its importance. The students expressed gratitude by giving self-made badges and cards to all the supporting staff in the school and also to different people like helper in the bus , peon, guard etc. The young minds of Lotus Valley were overwhelmed by this act of thankfulness and enjoyed the day.</p>
              <p>Following this, a feast was organised for all the supporting staff. All the helpers were assembled in the lawn and the students treated them with some refreshments. The smile on their faces on receiving the thank you cards and refreshments was a beautiful sight. </p>
              <p>The activity was designed to forge a better understanding of the labour community among our students who must learn to respect and appreciate their efforts. Overall, it was a fruitful day which taught our little ones to be thankful to everyone.</p>
              <p>#labourday</p>
              <div class="popup-gallery socialWork mt-4">
                <div class="row">
                  <div class="col-lg-4 col-md-4"><a href="/Images/labour1.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/labour1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/labour2.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/labour2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/labour3.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/labour3.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/labour4.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/labour4.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/labour5.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/labour5.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/labour6.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/labour6.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/labour7.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/labour7.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/labour8.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/labour8.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/labour9.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/labour9.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                </div>
              </div>


              <h3>COMMUNITY SERVICE IN COLLABORATION WITH GOONJ ORGANISATION</h3>
              <h4>“The smallest act of kindness is worth more than the grandest intention.”  <strong>-Oscar Wilde</strong></h4>
              <div class="clr"></div>
              <p>Community service is one of the best ways to help benefit the public or give back to your community. It does not only have positive effects on society, but it brings benefits to one's life and personal development. Through this, a culture of respect and care is nurtured towards all members of the community regardless of their economic conditions. The aim at school level is to develop a community of students to be more responsible, be more sensitive, and be more knowledgeable about the society and its needs.</p>
              <p>In order to inculcate such practices among our students, the members of the 'Community Service Society' of LVISNE collaborated with the 'GOONJ' organisation to serve out the less privileged with utmost happiness and joy. </p>
              <p>The collaboration was initiated through a one-hour session conducted by 'GOONJ'. The students of grades VI and VII participated in this session. The guest speakers for the session were Ms. Ishleen Kaur, Ms. Sonam, and Ms. Vatsala. The speakers from the organisation explained the work and functions of 'GOONJ'. They showcased different ways to reach out to the needy, how to collect funds, create awareness about such initiatives, and at last how to rebuild the lives of the less fortunate. The students actively participated in the session and even shared their experiences of donating essential things to the people around them. </p>
              <p>After the session, a 'Daan Utsav Week' was organised on the school premises. The students as well as teachers were encouraged to donate materials such as books, stationery items, bags, shoes, clothes, or any other valuable item that they wished to give. The members of the 'Community Service Society' went to each class in the middle wing and explained to the students the importance of donation and how we could bring smiles to the faces of the less privileged by doing a small gesture. The students, teachers, and even the nannies in the school actively participated in the 'Daan Utsav'. All of them donated a variety of school textbooks, notebooks, shoes, crayons, paints, clothes, toys, etc., all properly packed and in good condition. To highlight the cartons containing different materials, some students designed colourful posters with quotes.    </p>
              <p>The Utsav culminated with the school principal, Ms. Indu Yadav addressing and interacting with the students who participated in the donation drive. She encouraged and guided all the students on the importance of sharing and caring and giving back to society voluntarily. </p>
              <div class="popup-gallery">
                <div class="row">
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-1.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-2.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-3.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-3.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-4.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-4.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-5.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-5.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-6.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-6.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-7.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-7.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-8.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-8.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-9.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Daan-Utsav-9.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                </div>
              </div>
              <div class="clr"></div>
              <h3>SEWA PROJECT 2021-22</h3>
              <h4>SEWA (Social Empowerment through Work Education and Action) is an integral part of physical education initiated by the CBSE to focus on the mental, emotional and social health of children.</h4>
              <p>In our endeavour to strengthen the responsibility of the learners towards the less privileged, the disadvantaged, the society, and the environment, SEWA project was given to Grade 10 which they presented in the form of video and PPT on 9 July2021. This project helped the learners underline the significance of the interdependence of all human beings and our dependence on the environment in this shrinking global village. This programme aimed to raise awareness on addressing real-world problems and serving the community. </p>
              <p><strong>As a part of SEWA project, learners were supposed to choose any one of the following community service projects: -</strong> </p>
              <ul>
                <li> Contact and help isolated senior citizens during the pandemic.  </li>
                <li> Host virtual workshops on life skills and counselling for peers or children in your neighbourhood.</li>
                <li> Organise drives to generate funds and donate necessary house items, medicines, masks, and food to different people, organisations, and localities around you.</li>
                <li> Volunteer in tutoring, collecting, and distributing used and unused books and stationery material to underprivileged children.</li>
                <li> Showing Gratitude-SAY THANK YOU.</li>
                <li> Volunteer work with stray animals and organizations connected with the same cause.</li>
              </ul>
              <p>In the project learners showcased the effort made by them in organising drives to generate funds and donated necessary house items, medicines, masks, and food to the needy people, organisations, and localities around them. They have sent thank-you emails and texts to many frontline workers, created artwork and get well soon cards for patients in nursing homes and hospitals. They have donated food to healthcare facilities, put up homemade food to stray animals, initiated social media group, page and website to help the Covid patients with necessities, medicines, home-cooked food, details of nearby doctors, donors, hospitals etc. This social service of raising community awareness has helped the learners to develop as responsible citizens of our society.</p>
              <div class="popup-gallery">
                <div class="row">
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-1.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-2.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-3.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-3.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-4.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-4.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-5.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-5.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-6.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-6.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                  <div class="col-lg-4 col-md-4"><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-7.jpg" data-magnify="gallery" data-src="" data-group="a"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Sewa-Project-7.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a></div>
                </div>
              </div>
              <div class="clr"></div>
              <h3>Session on CBSE Career Counselling Portal</h3>
              <h4>“Understand that success is not an accident, but a planned event.”</h4>
              <p>Lotus Valley International School, Noida Extension, organised a session for the students of grades IX and X on 21st and 22nd October 2021. The purpose of the meeting was to introduce the students to the CBSE Career Guidance and Counselling Portal and to inform them about the procedure of registration on the same. The CBSE Career Portal provides information about careers, college directories, scholarships, and competitive entrance examinations. UNICEF, with partners from 13 state governments and the private sector, has customized career portals in regional languages to help 21 million adolescents access education and work-related resources</p>
              <p>Ms. Prachi Bhatnagar and Ms. Deepika Agarwal facilitated the session. The meeting began with the facilitators highlighting the significance of selecting a career consistent with an individual's aptitude, interest, and personality and after a consideration of the future prospects of the same. Following this, the students were provided with an MS Word document that was specially created for them to help them understand the process of registration on the Portal. The students were required to follow the steps of registration as they were being discussed in the meeting.</p>
              <p>After the registration process was explained, the students were given a demonstration of navigating the portal to explore information related to a wide range of careers. They were also guided about the ways of personalising their dashboard by feeding in their hobbies and subjects of interest.  The students from grades IX and X enthusiastically participated in the session showed keen interest and registered themselves on the portal.</p>
              <p>It was a fruitful session as it will help the students to make informed subject choices for grade XI as well as offer various career options in their chosen field.</p>

            </TabPanel>
            <TabPanel>
              <ul>
                <li>  Students, Parents, and Staff of LVIS,NE enthusiastically participated in the <strong>Tree Plantation Drive held on 20 July 2019</strong> on the field opposite to the school main gate. The students brought their own saplings and a total of 200 saplings were planted. Each sapling planted was adopted by the students and staff who pledged to nurture it. The objective of this was to sensitise students and create awareness about protecting the environment.</li>

                <li>  <strong>“Goonj”</strong> collection drive took place at Lotus Valley International School, Noida Extension from 26 August – 7 September  2019. Students from grade III-VIII actively participated for this cause by <strong>donating dry ration in huge quantities for the flood affected area in India</strong> – Maharashtra, Karnataka, Tamil Nadu, Kerala, Assam, Bihar and Andhra Pradesh. The collected ration was sent to the Goonj Foundation located at Sarita Vihar, New Delhi.</li>

                <li>  The <strong>students visited GOONJ- NGO centre</strong>, located at Delhi in September 2019. The selected students represented the school's community service spirit by donating dry ration for the flood affected states. The young change makers also got the opportunity to take a close look at the GOONJ centre. The children came back with new insights on how waste should not be discarded but recycled and re-used. This particular collection drive was successful in making the children realise that small actions can bring greater joy in the lives of others.</li>

                <li>  Students of <strong>grades VI-VIII conducted a host of activities</strong> in September'19 in accordance with the <strong>'Swacchta Hi Seva'</strong> campaign to sensitise everyone in school about hygiene, cleanliness and sanitation. Throughout the week, students participated enthusiastically in various activities such as cleanliness drive around the school, slogan writing, debate, quiz and poem recitation. The week long event culminated on a positive note with students undertaking the cleanliness pledge.</li>

                <li>  Students made sincere efforts at raising funds for charity during the EVS/S.Sc week by selling hand- made jewellery and other hand crafted items. <strong>Funds raised were donated by the students to Sai Bal Sansar, a non-formal educational centre located in Noida</strong>. During the visit, students of LVIS,NE got the opportunity to interact with the students of Sai Bal Sansar. This interaction made the learners appreciate their lives even more and develop an empathetic outlook towards the society. </li>

                <li>  On 20 February, 2020, participants of the <strong>Bake-o-Mania sale visited the Pawster Foundation</strong>, Greater Noida as part of the school's community service initiative. The Pawsters Foundation is a charitable animal welfare organisation run by Ms. Aparaajitha Ramadyani, a highly decorated Indian paramilitary officer, who has rescued and rehabilitated over 400 stray dogs' since 1996 and dedicated her life towards these voiceless souls. The animal shelter is a canine abode housing 107 wagging tails including pedigreed dogs that have been abandoned by their masters. The aim of the visit was to develop humane education that fosters compassion and respect for all living beings and the environment. The visit empowered students to become more compassionate and make informed choices that combat real‐world problems thereby making this world a better place for people, animals and the environment to live in.</li>

                <li> Lotus Valley International School, Noida Extension was privileged to be a part of the <strong>Annual Tamana Fashion Show held on 8 September, 2019 at Hyatt Regency, New Delhi</strong>. Tamana-a non profit organization working towards development of children with disabilities, has been organizing Annual Fashion Shows as a platform to create awareness for the disability sector. At the event, students of 'Tamana' walk the ramp hand in hand, shoulder to shoulder with professional models, renowned designers, dignitaries and students from mainstream schools. The theme for this years fashion show was 'My India My Pride'. The Principal, Ms. Indu Yadav along with two students of the school, Lavanya Pabreja (grade VII) and Kasshvi Saxena (grade VIII) elegantly walked the ramp for My India, My Pride.</li>
              </ul>
            </TabPanel>
            <TabPanel>
              <h3>OLD TOYS COLLECTION DRIVE FOR TOY BANK</h3>
              <div class="clr"></div>
              <p>There is no higher religion than human service. To work for the common good is the greatest creed. Woodrow T. Wilson Every child should have the opportunity to live their childhood and play with games and toys. With this thought in mind students of classes I - VII enthusiastically donated their old toys to help their not so privileged friends experience the joy of childhood. The collection will be donated to Toy Bank.  </p>
              <div class="clr"></div>
              <div class="clr"></div>
              <h3>COMMUNITY SERVICE & SOCIAL WORK</h3>
              <div class="clr"></div>
              <p>Engaging in community service provides students with the opportunity to become active members of their community and has a lasting, positive impact on society at large. Community service or volunteerism enables students to acquire life skills and knowledge, as well as provide a service to those who need it most.</p>
              <div class="clr"></div>
              <p>As a part of its Value Education and Social Outreach programme, Lotus Valley International School, Noida Extension took on the responsibility to be associated with “GOONJ” Foundation for helping the underprivileged. Their focus is to create a parallel economy which is not CASH based but it is TRASH based.</p>
              <div class="clr"></div>
              <div class="clr"></div>
              <h3>LABOUR DAY WAS CELEBRATED ON 2ND OF MAY</h3>
              <p>Lotus Valley International School, Noida Extension celebrated Labour Day on 2nd May, 2018 to acknowledge the efforts of Didis and Bhaiyas in keeping the school clean and helping the children. The event was conducted to make children aware of dignity of labour and to teach them respect for people who help us. A special assembly was conducted by the students of Class-I Robin on 'Kindness'.</p>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default CommunityServiceSocialWork
