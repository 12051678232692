import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const Dance = () => {
  return (
    <>
    <HeadTag title="Dance" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Co-Curricular <i className="fas fa-angle-right"></i>  Dance </span></h5>
        <h2> Dance</h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
               <h3 className="text-center">Dance is the hidden language of the soul of the body.</h3>  
               <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/dance.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/dance.jpg" className="art_craft_img img-fluid mx-auto d-block"  alt="Lotus Valley International School, Greater Noida"/></a> 
            
            </div>
            <div className="row acheivement_2024 mt-4">
          <div className='col-md-4'>
              <div className="img-block">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/lekh-lok.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              </div>
            </div>
            <div className='col-md-8'>
              <h3>HUNAR Lekh Lok Dance competition</h3>
              <p><b>School: </b> G D Goenka</p>
              <p><b>Event:</b> Hunar’24</p>
              <p><b>Date:</b> 8th August 2024</p>
              <p><b>Dance Name:</b> Lekh Lok</p>
              <p>Every accomplishment start with the decision to try.</p>
              <p>As a testament to their dedication, discipline, and passion for dance. Our grade V girls clinched victory in the interschool HUNAR Lekh Lok Dance competition, held at Gd Goenka School, Noida and secured second position.</p>
              <p>Congratulations to our extraordinary dance champions!</p>
               
            </div>

            

          </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default Dance
