import React, { useState } from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from '../Component/Footer'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const TrainingTrainers = () => {

  return (
    <>
      <HeadTag title="Training Trainers" />
      <Highlights />
      <InnerHaeder />
      <div className="inner_page">
        <div className="container-fluid flwidth testi_sec trainers">
          <div className="bgimage">
            <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  Co-Curricular  <i className="fas fa-angle-right"></i>   Training Trainers</span></h5>
            <h2>Training Trainers</h2>
          </div>
          <Tabs className="Tabs">
            <TabList>
              <Tab>2024 - 2025</Tab>
              <Tab>2023 - 2024</Tab>
              <Tab>2022 - 2023</Tab>
              <Tab>2021 - 2022</Tab>
              <Tab>2020 - 2021</Tab>
            </TabList>
            <TabPanel>
              <div class="accordion accordion-flush" id="accordionFlushExample">

                <div class="accordion-item">
                  <h2 class="accordion-header" id="posco-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#posco-headingOne" aria-expanded="false" aria-controls="posco-headingOne">
                      April
                    </button>
                  </h2>
                  <div id="posco-headingOne" class="accordion-collapse collapse" aria-labelledby="posco-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>POCSO Workshop</h3>
                          <p>A workshop on the POCSO Act - Protection of Children against Sexual Offences was held for both teaching and non-teaching staff on Monday, April 1, 2024 at Lotus Valley International School, Noida Extension. The workshop, conducted by experts Mr. Pradeep Sharma and Ms. Janhavi Singh was a pivotal component of project CACA (Children against Child Abuse), dedicated to sensitizing participants to the vital significance of the POCSO Act within the school context.</p>
                          <p>The workshop equipped everyone with the necessary awareness to identify signs of child abuse and the understanding of concepts such as appropriate and inappropriate touch. The staff members received comprehensive insights into the legal framework regarding steps to take when a child discloses such experiences to us. </p>
                          <p>The session concentrated on clarifying the different aspects of the act and emphasized the critical importance of promptly reporting any cases of child abuse.</p>
                          <p>#POCSOawareness #lvisne #educators #childrensafety #childrights #safespace #wellbeing</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/posco-workshop1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/posco-workshop2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/posco-workshop3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/posco-workshop4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/posco-workshop5.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/posco-workshop6.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/posco-workshop7.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/posco-workshop8.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/posco-workshop9.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="posco-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#posco-headingTwo" aria-expanded="false" aria-controls="posco-headingTwo">
                      March
                    </button>
                  </h2>
                  <div id="posco-headingTwo" class="accordion-collapse collapse" aria-labelledby="posco-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>Brain Gym Workshop (Pre-Primary Wing)</h3>
                          <p>A workshop was conducted by the school facilitators Ms Nidhi Jasrai and Ms Garima Mathur on Brain Gym on Saturday, 23 March 2024 for the Pre-Primary wing. The session aimed to equip the teachers with strategies on how to implement Brain Gym techniques effectively to enhance cognitive development and overall well-being of the students. The workshop included a series of exercises such as Lazy Eight, Brain Buttons, Thinking Cap, Cross Crawl etc. The session provided valuable insights to the teachers and motivated them to incorporate the concept of Brain Gym in their regular classes to accelerate learning and enhance students’ performance.</p>
                          <p>#braingym #lvisne #lazyeight #brainbuttons #thinkingcap #crosscrawl #cognitivedevelopment #wellbeing</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/braingym-1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/braingym-2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/braingym-3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/braingym-4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/braingym-5.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/braingym-6.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                      </div>
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>Workshop On New Education Policy (Pre-Primary Wing)</h3>
                          <p>The purpose of education is to make good human beings with skill and expertise. Enlightened human beings can be created by teachers.”- A.P.J. Abdul Kalam</p>
                          <p>A workshop on New Education Policy 2020 was conducted by the resource person Ms Chandeep Marwah on Monday, 26 March 2024 for the Pre-Primary wing. The objective of the workshop was to apprise the facilitators about key principles of NEP and its effective implementation. Ms Marwah elucidated the importance of nurturing students’ cognitive abilities and different ways to incorporate innovating teaching pedagogies and multidisciplinary approach using Blooms Taxonomy. She also shared various strategies on blending competency based teaching, integrating art and sports to foster creativity, problem-solving skills and a lifelong love for learning. </p>
                          <p>Overall, the workshop was very informative and enlightening with lots of enjoyable and brain-teasing activities to transform the overall learning experiences of the students.</p>
                          <p>#lvisne #neweducationpolicy #nep2020 #multidisciplinary #bloomstaxonomy #competencybased #artintegration</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/NEP1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/NEP2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/NEP3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/NEP4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/NEP5.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/NEP6.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/NEP7.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/NEP8.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/NEP9.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div class="accordion accordion-fl" id="accordionflExample">
              <div class="accordion-item">
                  <h2 class="accordion-header" id="accordionfJan">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#fl-headingJan" aria-expanded="false" aria-controls="fl-headingJan">
                      Jauary
                    </button>
                  </h2>
                  <div id="fl-headingJan" class="accordion-collapse collapse" aria-labelledby="fl-headingJan" data-bs-parent="#accordionfJan">
                    <div class="accordion-body">
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>Nature Nurture Training</h3>
                          <p>"Education is the kindling of a flame, not the filling of a vessel." – Socrates</p>
                          <p>The Lotus Valley International School, Noida Extension's kindergarten facilitators engaged in a thorough training session on Monday, 9 January 2024. Led by NatureNurture, the curriculum partners, the session commenced with interactive warm-up activities like the "Stop, Walk, Name & Clap" game and "The Queen and Blindfold" team-building exercise. The focus was on enhancing skills such as listening, gross motor abilities, concentration, leadership, and trust-building.</p>
                          <p>The resource person stressed the significance of micro planning, fostering peer-to-peer learning, and sharing best practices among educators. Tech readiness, including the use of tools like Google Drive and "Show What You Know" quizzes, was a key aspect. Circle time demonstrations showcased the integration of diverse domains, including health, hygiene, and PSED.</p>
                          <p>Educators actively participated in the session, concluding with reflections on their unique takeaways, making the training both insightful and interactive.</p>
                          <p>#trainingtheteachers #learning #LVISNE #NatureNurture</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nature-1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nature-2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nature-3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nature-4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>                        
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nature-5.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>                        
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nature-6.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>                        
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nature-7.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>                        
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nature-8.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>                        
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nature-9.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="accordionfthree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#fl-headingThree" aria-expanded="false" aria-controls="fl-headingThree">
                      October
                    </button>
                  </h2>
                  <div id="fl-headingThree" class="accordion-collapse collapse" aria-labelledby="fl-headingThree" data-bs-parent="#accordionfthree">
                    <div class="accordion-body">
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>Nurturing the Nurturer - workshop</h3>
                          <p>The workshop started by encouraging teachers to embrace themselves and recite affirmations.</p>
                          <p>As the session progressed, teachers were invited to reflect on their "jar of worries" and their "moments of happiness." A few teachers eagerly opened up about their personal struggles, which inspired others to share their own life journeys.</p>
                          <p>The discussion then shifted towards the topic of mental health and how often we tend to overlook it, choosing instead to seek help from spiritual healers or pandits rather than from any certified mental health professionals. The teachers candidly shared amusing anecdotes about their experiences in this regard.</p>
                          <p>The workshop concluded on a heartfelt note, emphasizing the importance of prioritizing self-care and mental well-being above all else.</p>
                          <p>Towards the conclusion of the workshop, the story of the legendary Sindbad the Sailor was shared, highlighting his fearless and daring voyages. Sindbad's unwavering belief in his dreams and his refusal to give up served as an inspiring example.</p>
                          <p>Following the story, the teachers came together to celebrate with a victory dance, with some even singing along. The participants took away a valuable lesson from Sindbad's story, learning to apply his resilience and determination to face challenges in their own lives.</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nurturing-1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nurturing-2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nurturing-3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Nurturing-4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="fl-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#fl-headingOne" aria-expanded="false" aria-controls="fl-headingOne">
                      May
                    </button>
                  </h2>
                  <div id="fl-headingOne" class="accordion-collapse collapse" aria-labelledby="fl-headingOne" data-bs-parent="#accordionflExample">
                    <div class="accordion-body">
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>Financial Literacy</h3>
                          <p><i>“An investment in financial knowledge, pays the best interest”</i></p>
                          <p>The workshop was led by renowned resource person, Mr. Anil Saxena, qualified CA , who has extensive expertise in the field of finance and owns a platform called R K Wealth with a purpose to guide small investors .</p>
                          <p>The workshop commenced with the explaining of concept like Investment, budgeting and savings and their working principles. He focused on NPS, a voluntary pension scheme launched by the Indian government. Teachers gained insights into the different investment options available within the NPS framework and the long-term retirement planning benefits it offers.</p>
                          <p>He also introduced mutual funds and elaborated on their structure, types, and benefits and the importance of diversification. They were also briefed on the different categories of mutual funds and the risk-return trade-off associated with each.</p>
                          <p>The final session focused on SIP, a disciplined investment approach that allows individuals to invest regularly in mutual funds. Participants gained insights into how SIPs can help in achieving long-term financial goals and creating wealth over time.</p>
                          <p>The financial literacy workshop conducted by the resource person proved to be a valuable learning experience for the teachers at Lotus Valley International School Noida Extension. The workshop's success in enhancing financial literacy among educators will undoubtedly enable them to impart valuable financial knowledge to their students, empowering the younger generation to develop sound financial habits.</p>
                          <p>The workshop concluded with closing remarks from Mr. Saxena, expressing his gratitude to the participants for their active participation and enthusiasm. The attendees were encouraged to provide feedback on the workshop's content, organization, and overall effectiveness.</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fl1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fl2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fl3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fl4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fl5.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fl6.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fl7.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fl8.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fl9.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>

                      </div>
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>Workshop On Fitness</h3>
                          <p>The workshop on mindfulness, nutrition, and overall health for teachers was conducted by Ms. Kompal Gaur on Monday, 22.05.2023 for the teachers with the aim of enhancing their awareness and understanding of these essential topics. The workshop provided valuable insights into the benefits of mindfulness practices, the significance of proper nutrition, and overall health promotion strategies.</p>
                          <p>The workshop began with a warm welcome and introduction to the topics that would be covered throughout the session. The resource person, Ms.Kompal briefly outlined the importance of mindfulness, nutrition, and overall health for teachers and their impact on both personal well-being and professional effectiveness.</p>
                          
                          <p><b>Mindfulness:</b></p>
                          <p>An expert in mindfulness conducted a session on the fundamentals of mindfulness and its relevance.</p>
                          <p>Participants engaged in various mindfulness exercises, including breathing techniques, body scans, and guided meditations.</p>
                          <p>The facilitator explained how incorporating mindfulness practices into daily routines can reduce stress, improve focus and attention, and enhance overall emotional well-being.</p>

                          <p><b>Importance of Nutrition:</b></p>
                          <p>She further spoke about the significance of proper nutrition for teachers and its impact on cognitive function, energy levels, and overall health.</p>
                          <p>The session covered topics such as balanced meal planning the role of overall exercise.</p>
                          <p>Interactive discussions and Q&A sessions allowed participants to clarify doubts and gain practical tips for incorporating healthy workoout habits into their busy schedules.</p>
                          
                          <p><b>Strategies for Overall Health Promotion:</b></p>
                          <p>This session focused on holistic health approaches, encompassing physical, mental, and emotional well-being.</p>
                          <p>The resource person discussed the importance of regular exercise, adequate sleep, stress management techniques, and fostering positive relationships.</p>
                          <p>Participants were encouraged to develop personalized well-being plans, setting realistic goals for improving their overall health and implementing self-care practices.</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fit1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fit2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fit3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fit4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fit5.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fit6.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/fit7.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>

                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
            </TabPanel>
            <TabPanel>
              <div class="accordion accordion-unique" id="accordionuniqueExample">
              <div class="accordion-item">
                  <h2 class="accordion-header" id="uniqueMar-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#uniqueMar-headingOne" aria-expanded="false" aria-controls="uniqueMar-headingOne">
                     March
                    </button>
                  </h2>
                  <div id="uniqueMar-headingOne" class="accordion-collapse collapse" aria-labelledby="uniqueMar-headingOne" data-bs-parent="#accordionuniqueExample">
                    <div class="accordion-body">
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>NATURE NURTURE TRAINING (PRE-PRIMARY WING)</h3>
                          <p>“Wisdom is not a product of schooling but of lifelong attempt to acquire it”- Albert Einstein </p>
                          <p>The Pre-Primary wing of Lotus Valley International School, Noida Extension, in collaboration with its curriculum partner NatureNurture, organized a training session on Tuesday, 19.03.2024, focusing on the "Integration of music and movement in classrooms." </p>
                          <p>During the session, facilitators were equipped with innovative strategies aimed at creating dynamic learning environments. Engaging games such as drama cake, music notation, fruit rhythm, and more were introduced to the educators, empowering them with cutting-edge methods for integrating music, movement, and art into their teaching practices.</p>
                          <p>By providing educators with these tools and techniques, Lotus Valley International School reaffirmed its commitment to ensuring that its faculty remains at the forefront of best practices in education. This initiative not only enhances the learning experience for students but also underscores the school's proactive approach to professional development and its focus on creating well-rounded educational experiences.</p>
                          <p>#learning #training #teacherstraining #LVISNE</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/nature-pre-1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/nature-pre-2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/nature-pre-3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/nature-pre-4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/nature-pre-5.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>                       

                      </div>
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>TEACHERS TRAINING – TRANSITION (KINDERGARTEN)</h3>
                          <p>“Education is the passport for the future for tomorrow belongs to those who prepare for it today!’- Malcolm X </p>
                          <p>To empower and equip the facilitators of kindergarten during the initial days of transition of our learners Lotus Valley International School, Noida Extension in partnership with NatureNurture, conducted a session on Monday, 20th March, 2023.</p>
                          <p>The resource, Ms. Shreya Singh and Ms. Geeta, guided the facilitators through the early days of transitioning of young learners to help foster a close relationship between the learners and the facilitators. They emphasized the NatureNurture learning cycle, from planning for a new session to reflecting on and reviewing the entire process. They recommended a variety of energizing and team-building activities that could be used in classrooms to make learning interesting. The training session gave the facilitators the opportunity to review their instructional techniques, prepare for the exercise, and be inspired to put the activities into practice in their teaching-learning methods.</p>
                          <p>#LVISNE #learingandfun #teacherstraining #transition</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/tt-transition-1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/tt-transition-2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/tt-transition-3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/tt-transition-4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                      </div>                       
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="unique-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#unique-headingOne" aria-expanded="false" aria-controls="unique-headingOne">
                      November
                    </button>
                  </h2>
                  <div id="unique-headingOne" class="accordion-collapse collapse" aria-labelledby="unique-headingOne" data-bs-parent="#accordionuniqueExample">
                    <div class="accordion-body">
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>Unique Undergraduate Courses Workshop</h3>
                          <p>A workshop on Unique Undergraduate Courses was organized for the students of grade-11, to make them more aware about the combinations of courses available, nationally and internationally. The resource person- Mr. Ritesh Jain from Pratham- Lead the Way, was warmly welcomed, and was handed over a sapling by the respected coordinator- Ms. Sheetal Mittal.</p>
                          <p>He apprised the students about the purpose of profile building, and recommended them to create an extended portrait of themselves; for an easy admission procedure. The session majorly threw a light on; </p>
                          <p>various facets of different offbeat careers that are in demand, these days. He further discussed in detail,the unique combinations of subjects; offered in several universities; National- Ashoka University, Delhi University, Flames University, Gujrat National Law University, Indian Statistical Institute, Palaksha University, and International- Ecole Polytechnique, Harvard University, Kings College, Oxford </p>
                          <p>University, University of Sydney, Waterloo University. </p>
                          <p>He also discussed various competitive exams and scholarships that the students can appear in, and their eligibility criteria. </p>
                          <p>The workshop was indeed an interactive one, and was concluded with the question and answers round. It was indeed an enriching experience, for all the students.</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Undergraduate1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Undergraduate2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/Undergraduate3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                         

                      </div>
                       
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="unique-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#unique-headingThree" aria-expanded="false" aria-controls="unique-headingThree">
                      September
                    </button>
                  </h2>
                  <div id="unique-headingThree" class="accordion-collapse collapse" aria-labelledby="unique-headingThree" data-bs-parent="#unique-headingThree">
                    <div class="accordion-body">
                      <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>Effective Classroom Management</h3>
                          <p>An online workshop on the topic ‘Effective Classroom Management’ was conducted by the counsellors- Ms. Shivani Seth and Ms. Srishti Bhola on 13.09.2022 for the educators of grades 6 to 11. The topic was introduced by Ms. Shivani Seth, and then the platform was handed over to Ms. Srishti Bhola, who started by discussing what makes a great teacher. The audience was asked to think of the greatest teacher; they ever had and elaborate on his/her attributes that made her great. Teachers participated and found the question interesting. The features of an effective classroom were then discussed. Ms. Shivani Seth; then elaborated upon the strategies for classroom management in specific domains like handling inattention, inappropriate behaviour, hyperactivity, and establishing effective communication. For each of the domain discussed, different case scenarios were shared, and the teachers were asked to come up with solutions. The feedback from the teachers was highly insightful and creative.</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/effective-1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/effective-2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/effective-3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/effective-4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div> 
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/effective-4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                        </div></div>                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingtt">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsett" aria-expanded="false" aria-controls="flush-collapsett">
                      March
                    </button>
                  </h2>
                  <div id="flush-collapsett" class="accordion-collapse collapse" aria-labelledby="flush-headingtt" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                    <div className="row training_img">
                        <div className='col-lg-12'>
                          <h3>Teachers Training(Pre-Primary Wing)</h3>
                          <p>“Curriculum should help children make a deeper understanding of their own experiences.” </p>
                          <p>LVIS believes in continuously upgrading the skills of its facilitators. Thus, a virtual curriculum induction was organized for the facilitators of Lotus Valley International School, Noida Extension by Ms Anuradha Sinha, curriculum partner for Grades I & II on Wednesday, 16 March 2022.</p>
                          <p>The purpose of the session was to highlight the importance of thematic and integrated learning, thereby focusing on the important elements of education for learners like Language Development, Cognitive Development, Fine and Gross Motor Skills, and Bloom’s Taxonomy etc. Ms Anuradha, constructively guided the facilitators with the steps to enhance the reading skills of learners at initial stages. She emphasized on the technique of ‘See, Think and Wonder’ in order to promote the creative thinking of young explorers. In addition, a few dynamic solutions to routine learning problems of classrooms were also discussed.</p>
                          <p>Towards the end of the session, facilitators got an opportunity to share their valuable insights to the curriculum and highlight the core areas of improvement. It was an interactive session which enabled facilitators to explore different books and teaching techniques provided by esteemed curriculum partner, Ms Anuradha.</p>
                          <p>#lvisne #teachers #curriculum #teaching #training #cognitivedevelopment #creativethinking #languagedevelopment #integratedapproach</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/tt-pp-1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/tt-pp-2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/tt-pp-3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/tt-pp-4.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>                        
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/tt-pp-5.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSix">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                      September
                    </button>
                  </h2>
                  <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className="row pdf_sec">
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-september-activity.pdf" target="_blank">
                              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                              <h5>September </h5>
                              Activity
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-september-nur-II.pdf" target="_blank">
                              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                              <h5>September </h5>
                              Class Pre Nur - II
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="box">
                            <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-september-III-X.pdf" target="_blank">
                              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
                              <h5>September </h5>
                              Class III - X
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      August
                    </button>
                  </h2>
                  <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className="row pdf_sec">
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-August-activity.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>August </h5>  Activity</Link></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-August-nur-II.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>August </h5>  Class Pre Nur - II</Link></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-August-III-X.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>August </h5>  Class III - X</Link></div></div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      July
                    </button>
                  </h2>
                  <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className="row pdf_sec">
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-july-activity.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>July </h5>  Activity</Link></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-july-nur-II.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>July </h5>  Class Pre Nur - II</Link></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-july-III-X.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>July </h5>  Class III - X</Link></div></div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      June
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className="row pdf_sec">
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-june-activity.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>June </h5>  Activity</Link></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-june-nur-II.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>June </h5>  Class Pre Nur - II</Link></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Training-Data-june-III-X.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>June </h5>  Class III - X</Link></div></div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      May
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div className="row pdf_sec">
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/FEEDBACK-MAY-2021.pdf.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>May </h5>  Activity</Link></div></div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      April
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">

                      <div className="row pdf_sec">
                        <div className="col-lg-3 col-md-6"><div className="box">
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/FEEDBACK-APRIL-2021.pdf.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /><h5>April </h5>  Activity</Link></div></div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div class="accordion accordion-flush" id="accordionFlushExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThrteen1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThrteen1" aria-expanded="false" aria-controls="flush-collapseThrteen1">
                      Workshop on 'Big Book Workshop – Pre Primary'
                    </button>
                  </h2>
                  <div id="flush-collapseThrteen1" class="accordion-collapse collapse" aria-labelledby="flush-headingThrteen1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop13.jpg" class="inner_img" alt="Lotus Valley International School, Greater Noida" />
                        <p>“Books are a uniquely portable magic.” – Stephen King To emphasise the importance of reading and utilizing the books as a key resource in the classes, a session on Big Book Programme was conducted on Friday, 18 October 2019. The resource person Ms. Neelam Narang from Scholastic enlightened the Pre Primary teachers on the relevance of reading in today’s time and its effective use in teaching various concepts. It began with a mirror reflection activity followed by a discussion on the characteristics of 2-7 years old children. Ms. Narang emphasized the need to mould children to become lifelong readers and encourage their passion towards reading. She made everyone acquainted with the different parts of a Big Book. The key takeaway was creating opportunities for children to think and imagine while reading and giving them enough space to perceive and interpret the story in their own way. The advantages of Big Books over the traditional story books were also discussed. The important aspects of reading aloud such as the change of tone, mood, intensity and pace of reading were emphasized as well. Using these big books in the classroom as a method of integrating various subjects was explained effectively. It was an interactive, engaging and fruitful session with great take aways for the teachers.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwel1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwel1" aria-expanded="false" aria-controls="flush-collapseTwel1">
                      Workshop on 'Session on Menstruation and Menstrual Hygiene'
                    </button>
                  </h2>
                  <div id="flush-collapseTwel1" class="accordion-collapse collapse" aria-labelledby="flush-headingTwel1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop12.jpg" alt="LVISGN" class="inner_img" />
                        <p>A session on menstruation and menstrual hygiene was conducted for students of grades VI to VIII by Dr. Kanika Gera, Consultant Gynecologist, Fortis Hospital, Noida. The session gave children an opportunity to understand how and why females menstruate. The speaker also gave students some useful insights into maintaining good hygiene during menstruation, potential risks of keeping poor hygiene during menstruation and ways to effectively and confidently manage the first period. The session helped the children break the taboo around the subject and raise their concerns freely.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingElev1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseElev1" aria-expanded="false" aria-controls="flush-collapseElev1">
                      Workshop on 'Author Speak by Ms Nandana Dev Sen'
                    </button>
                  </h2>
                  <div id="flush-collapseElev1" class="accordion-collapse collapse" aria-labelledby="flush-headingElev1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop11.jpg" alt="LVISGN" class="inner_img" />
                        <p>The students of grades II, III, IV & V of Lotus Valley International School Noida Extension had a unique opportunity of interacting with the renowned children's author, actor, director and child rights activist, Ms Nandana Dev Sen in the session 'Author Speak' on 1 August, 2019. The versatile author, daughter of the Nobel Laureate Amartya Sen was warmly welcomed by the school Principal, Ms Indu Yadav, with a sapling. Ms Sen began the session by reading excerpts from her books. She interacted with children and shared experiences from her life that inspired her to write. Replete with life lessons, each story, interestingly narrated, left students clamoring for more. Towards the end of the session, Ms Sen took queries from the students related to book writing. It was an enriching, engaging and fruitful session which helped children expand the horizon of their learning.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTen1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen1" aria-expanded="false" aria-controls="flush-collapseTen1">
                      Workshop on 'ARTiculate' at India International Center
                    </button>
                  </h2>
                  <div id="flush-collapseTen1" class="accordion-collapse collapse" aria-labelledby="flush-headingTen1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop10.jpg" alt="LVISGN" class="inner_img" />
                        <p>“The principle of true art is not to portray, but to evoke”- Jerzy Kosinski The teachers attended a one day workshop ‘ARTiculate’ on 17 August 2019 at Indian International Center, New Delhi. The workshop was initiated by Ms. Aditi Misra, former Principal of Delhi Public School, Gurgaon. She did ‘set the tone’ for the workshop by stressing on integration of art in every aspect of school curriculum like learning life skills, language and communication, problem solving etc. She emphasized that for each artist, art should come from the heart and it should work as therapy for all. She stressed on individuality of each artist and various stages of artistic development and concluded by encouraging the teachers to take bolder steps as educators to bring out creative and out of the box thinking in our learners. Ms. Madhumita Nandi and Ms Bhavna Srivastava introduced some activities using simple shapes like circles and triangles, which were converted into various hand toys and classroom education tools. They introduced some innovative hands on activity tools like Tangrams, Micrography, Spoonerism, Droodles, colour scheme to teach symmetry ‘Rangometry’ etc. The workshop was quite informative and added value to the attendees.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingNine1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine1" aria-expanded="false" aria-controls="flush-collapseNine1">
                      Celebration of Roald Dahl's birthday and session on storytelling
                    </button>
                  </h2>
                  <div id="flush-collapseNine1" class="accordion-collapse collapse" aria-labelledby="flush-headingNine1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop9.jpg" alt="LVISGN" class="inner_img" />
                        <p>“Having power is not nearly as important as what you choose to do with it”- Roald Dahl Storytelling is an ancient and valuable art that extends around the globe. In this unit, students develop their own storytelling talents, apply the techniques of storytelling, create storytelling guides, and perform a story for an audience. LVISNE celebrated the birthday of a very famous author Roald Dahl which was followed by a story telling session by Ms.Sonia Bareja. Ms.Sonia is a versatile, enthusiastic and creative demonstrator cum educator involved in Training & Creative Development of young adults and children. After the session there was a quiz on Roald Dahl’s work. Students enjoyed the session immensely.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingEight1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight1" aria-expanded="false" aria-controls="flush-collapseEight1">
                      Workshop on ‘An introductory session on 'Teacher as a Counselor'
                    </button>
                  </h2>
                  <div id="flush-collapseEight1" class="accordion-collapse collapse" aria-labelledby="flush-headingEight1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop8.jpg" alt="LVISGN" class="inner_img" />
                        <p>An enriching session on 'Teacher as a Counselor' was conducted by Dr. V.S Ravindran on 24th June, 2019 at LVISNE. It was an interactive session where Dr. Ravindran talked about the different roles of a teacher and how a teacher can be a counselor. A teacher’s role in the past few years has shifted from being a lecturer to a facilitator, a coach and mentor and finally to a counselor who is responsible for the holistic development of a child. A teacher has to don different hats at different times in order to encourage learning as well as build a healthy rapport with the students. He emphasized on the fact that the content is driven by students as well and it is no more teacher-centric. He also said that a supportive atmosphere should be created for every child where every child is respected and valued. A teacher should be friendly with students, but must know where to draw the line. For reinforcing good values and positive behavior in children, teachers should catch them doing the ‘right things’ rather than repeatedly pointing out wrong behavior or mistakes. He regaled the teachers with a lot of anecdotes from his career as an educator, psychologist and counselor, spanning more than 35 years. It was an informative and interactive session with the message- ‘I am not A teacher, I am THE teacher.’</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSeven1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven1" aria-expanded="false" aria-controls="flush-collapseSeven1">
                      Workshop on "Mini MBA at School using Hub & Spoke model"
                    </button>
                  </h2>
                  <div id="flush-collapseSeven1" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop7.jpg" alt="LVISGN" class="inner_img" />
                        <p>A workshop was conducted for the teachers of LVISNE by Mr. Amit Agarwal, an MBA and corporate professional, on the art of building key soft skills like optimism, innovation, collaboration, future readiness and adaptability. Children, while growing up, develop a comfortable cocoon of family, close friends and playmates. While that is an intrinsic part of growing up, it can sometimes lead to hesitation in interacting with people who are not part of their social group. In order to prevent this social inhibition, children should be given opportunities to interact, communicate and collaborate as a team with people, apart from their social group. The ‘Hub and Spoke’ model advocated by him can facilitate collaboration between students of different calibre and interests. Giving them projects in which, they work and interact towards achieving a common goal can lead to better social, thinking and communication skills. The teachers talked about various collaborative learning strategies which they have been implementing in their classes and the positive results, thus, seen in many students. It was another step towards providing an enriching ecosystem for teacher empowerment.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSix1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix1" aria-expanded="false" aria-controls="flush-collapseSix1">
                      Workshop on ‘Teachers plant seeds that grow forever'
                    </button>
                  </h2>
                  <div id="flush-collapseSix1" class="accordion-collapse collapse" aria-labelledby="flush-headingSix1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop6.jpg" alt="LVISGN" class="inner_img" />
                        <p>’The good teacher explains. The superior teacher demonstrates. The great teacher inspires.’’ -William Arthur Ward. An enriching and interactive workshop on “Teachers plant seeds that grow forever’’ was conducted for teachers on June 27th, 2019 at LVISNE. The workshop was spearheaded by Ms. Usha Pathania, Director-Academics at Mahattattva Education Advisory which empowers schools across the nation. The session aimed at encouraging and motivating the teacher-facilitators to bring in innovations in teaching-learning process. The workshop focused at enhancing the teaching strategies in classrooms for an effective and lifelong learning. It emphasised at enhancing the 21st century skills of students, engaging them in a learning process that goes beyond textbook and create a conducive learning environment for students to find a solution to problems in order to bring about positive changes for a sustainable future. The workshop encompassed a number of activities that were done during the session. The activities were performed by all teachers, like Role Playing, Magazine Cover Making, Jingles, and Street Play. The purpose of the workshop was to ensure that everyone participated in order to understand any concept – broadly, the workshop focused on the idea of Art Integration. The multidisciplinary approach to teaching allows for a better learning outcome thereby, making the child make connect with the outside world and making teaching a meaningful exercise.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFive1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive1" aria-expanded="false" aria-controls="flush-collapseFive1">
                      Workshop on ‘The Art of Story-Telling- Session with Ms. Simmi Srivastava'
                    </button>
                  </h2>
                  <div id="flush-collapseFive1" class="accordion-collapse collapse" aria-labelledby="flush-headingFive1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop5.jpg" alt="LVISGN" class="inner_img" />
                        <p>The story-telling session with Ms. Simmi Srivastava was held on 9 August, 2019 and Teachers across all grades from Pre-Nursery to Grade 4 were in attendance. Ms. Simmi's session highlighted the need to implement story-telling in the classrooms, to transform and enliven the teaching-learning process. The session began with Ms. Simmi narrating the ‘Hungry Caterpillar’ complete with expressions and intonation. It was a surprise to see that although the story is generally for pre-primary students, it could also be woven around topics and concepts usually difficult to understand. Most teachers expressed concern for not being able to hold a student's attention for more than a short span of time. However, Ms. Simmi's narration of 'Geet ka Kamal', was a clear example of how involvement of the students in a story telling session is guaranteed to improve the attention. Lastly, the session brought home the point that story-telling also caters to all three types of learning styles - kinaesthetic, auditory and visual, which is why the lesson or the concept being taught is better absorbed and understood. ‘As educators, we are only as effective as what we know.’ Rightly said, teachers are also learners, and they need to continually hone their skills, improve and implement new tools to make teaching more effective.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour1" aria-expanded="false" aria-controls="flush-collapseFour1">
                      Workshop on ‘Workshop on Critical and Creative Thinking'
                    </button>
                  </h2>
                  <div id="flush-collapseFour1" class="accordion-collapse collapse" aria-labelledby="flush-headingFour1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop4.jpg" alt="LVISGN" class="inner_img" />
                        <p>"You can't use up creativity. The more you use, the more you have"- Maya Angelou Lotus Valley International School, Noida Extension focuses on the overall development of students. The endeavor is to create curious minds and provide them with the means to ignite creative and independent thinking. For this, an interactive and informative session was conducted by Ms. Suhasini Kanwar on 25th June 2019. Ms Suhasini holds experience in training teachers to match up with the constantly changing teaching strategies. The session focused on multiple intelligences and collaborative strategies that can be implemented in classrooms and to achieve effective learning for the students. The session also threw light on critical and creative thinking, how it can be inculcated and encouraged in students and integrated with the curriculum through activity-based learning. The session was centred around facilitating teachers with practical examples and strategies that can be incorporated in the classroom setting to make learning more effective, interactive and impactful.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree1" aria-expanded="false" aria-controls="flush-collapseThree1">
                      Workshop on ‘Workshop on Story Telling - Once Upon A Time'
                    </button>
                  </h2>
                  <div id="flush-collapseThree1" class="accordion-collapse collapse" aria-labelledby="flush-headingThree1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop3.jpg" alt="LVISGN" class="inner_img" />
                        <p>A workshop on Story Telling - Once Upon A Time with participants from various schools on September 14, 2019 was being organised at Lotus Valley International School, Noida which was attended by Ms. Surbhi Juneja and Ms. Nidhi Jasrai from Lotus Valley International School, Noida Extension. An interesting session on playing with stories was conducted by Ms. Nupur Aggarwal. It focused on how to spark imagination, build vocabulary, ingrain values and build a connection with the children through stories. The session gave a valuable insight on how a story comes alive through sounds, actions, voice modulation, props and repetition of phrases.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo1" aria-expanded="false" aria-controls="flush-collapseTwo1">
                      Workshop on ‘Session on Positive Psychology'
                    </button>
                  </h2>
                  <div id="flush-collapseTwo1" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop2.jpg" alt="LVISGN" class="inner_img" />
                        <p>A workshop was held at Lotus Valley International School, Noida Extension on 'Positive Psychology - an introduction to your best version'' by team members of Youniverse, Mr. Sanjeevan Sandhu and Ms. Tanya Bakshi. The resource persons emphasized on mental health for every individual to save them from getting into anxiety and depression. Passion and Purpose were talked about and it was reiterated that ‘Passion’ is what we do solely for ourselves while ‘Purpose’ is when we add value to the lives of others. Also, Emotional Evaluation done everyday ensures that we do not hold grudges for a very long time because it ultimately ends up harming our own mental health. Mr. Sanjeevan & Ms. Tanya also talked about the importance of growth mindset which is ideally what everyone should practice and how it works on positive, problem-solving, optimistic thoughts. They also emphasized practicing gratitude in our everyday lives in order to feel more positive and rejuvenated. The workshop ended on a positive note with a vote of thanks by the Principal, Ms. Indu Yadav.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                      Workshop on 'Session on Empathy by Goonj Foundation'
                    </button>
                  </h2>
                  <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample1">
                    <div class="accordion-body">
                      <div class="circular_sec">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/workshop1.jpg" alt="LVISGN" class="inner_img" />
                        <p>"The world has enough for everyone's needs, but not everyone's greed" -Mahatma Gandhi .As the part of the social surrounding, the Lotus Family considers it a duty to give as much back to the society as is taken from them. For this, Lotus Valley International School, Noida Extension hosted a team from Goonj Foundation on September 11, 2019 to conduct a session on Empathy. The interactive session was conducted for Classes IV and V to generate awareness about the difficulties that the underprivileged children in the rural and urban areas have to face to make ends meet. The students were also encouraged to not discard their belongings but to contribute and share them. The session was concluded with a message and a promise of trying to make a difference to the lives of those who actually need it.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TrainingTrainers
