import React, { useEffect } from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const AdmissionProcess = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Admission Process- Lotus Valley Greater Noida'; // Change 'Your Website Name' to your actual website name
  }, []);
  return (
    <>
    <HeadTag title="Admission Process" />
      <Highlights />
      <InnerHaeder />
      <div className="inner_page">
        <div className="container-fluid flwidth">
          <div className="bgimage">
            <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Admission <i className="fas fa-angle-right"></i>  Admission Process </span></h5>
            <h2> Admission Process</h2>
          </div>
          <div className="row">

            <div className="col-lg-8 col-md-12">
              <h3>REGISTRATION</h3>
              <ul>
                <li>Form needs to be filled online from the website - <b> <Link to="https://www.lviscampuscare.net/"> www.lviscampuscare.net</Link></b></li>
                <li> The eligible age for Nursery admission is 3+ years as on 31st March 2025.
                  <ul>
                    <li>Lower KG- 4+ years as on 31st March 2025</li>
                    <li>KG- 5+ years as on 31st March 2025</li>
                    <li>I - 6+ years as on 31st March 2025</li>
                  </ul>
                </li>
                <li>  Incomplete registration form will not be processed.</li>
                <li> On completion of the online registration, parents will be called for further admission process within 7-10 working days.</li>
                <li>  Parents will be notified about the admission through e-mail.</li>
                <li>  Once the admission is confirmed the fee has to be deposited within the stipulated time period.</li>
                <li><b>Mandatory Documents</b> required for the final admission process are:
                  <ul>
                    <li>1. Self - Attested copy of the Birth Certificate</li>
                    <li>2. Self - Attested copy of the Immunization Chart (till Grade I)</li>
                    <li>3. Self - Attested copy of the Aadhar Card of both parents</li>
                    <li>4. A self-attested copy of the child's Aadhar Card</li>
                    <li>5. Self - Attested copy of Final Report Card (Grade II upwards)</li>
                    <li>6. TC Original (Grade II upwards) to be submitted within one month on the completion of current academic session</li>
                    <li>7. Passport Size Photo (4 photographs each of both parents and child)</li>
                    <li>8. PEN (Permanent Education Number)</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-12">
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/adm.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              <h3>Click on below Download Button to download the Registration Form</h3>

              <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/pdf/Registration-Form.pdf" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/download.png" alt="Lotus Valley International School, Greater Noida" /></Link>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AdmissionProcess
