import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const Music = () => {
  return (
    <>
    <HeadTag title="Music" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Co-Curricular  <i className="fas fa-angle-right"></i>  Music</span></h5>
        <h2>Music</h2>
        </div>
        <h3>Music expresses that which cannot be said and on which it is impossible to be silent.</h3>
  
        <div className="row img_data_sec">
        <div className="col-lg-4 col-md-6"><article> <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/music1.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/music1.jpg" className="art_craft_img img-fluid mx-auto d-block"  alt="Lotus Valley International School, Greater Noida"/></a> </article></div>
        <div className="col-lg-4 col-md-6"><article> <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/music2.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/music2.jpg" className="art_craft_img img-fluid mx-auto d-block"  alt="Lotus Valley International School, Greater Noida"/></a> </article></div>
        <div className="col-lg-4 col-md-6"><article> <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/music3.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/music3.jpg" className="art_craft_img img-fluid mx-auto d-block"  alt="Lotus Valley International School, Greater Noida"/></a> </article></div>
        </div>
        <div className="row acheivement_2024">
          <div className='col-md-4'>
              <div className="img-block">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LVISGN/public/Images/abhivyakti.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              </div>
            </div>
            <div className='col-md-8'>
              <h3>Abhivyakti</h3>
              <p><b>School: </b> Salwan Public School</p>
              <p><b>Event:</b> Inter-School Event Abhivyakti </p>
              <p><b>Date:</b> 9th August 2024</p> 
              <p>The limits of my language mean the limits of my world." - Ludwig Wittgenstein</p>
              <p>We are delighted to announce that our young orator from Grade IV Georgia – Tanisq Rohilla with his articulate and impactful expressions participated in an Inter-School Event Abhivyakti held at Salwan Public School and secured III Position. </p>
              <p>Kudos to the young speaker!</p>
               
            </div>

            

          </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default Music
