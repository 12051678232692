import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const OurEmblem = () => {
  return (
    <>
      <HeadTag title="Our Emblem" />
      <Highlights />
      <InnerHaeder />
      <div className="inner_page">
        <div className="container-fluid flwidth">
          <div className="bgimage">
            <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> About Us  <i className="fas fa-angle-right"></i> Our Emblem </span></h5>
            <h2>Our Emblem</h2>
          </div>
          <p><img src="Images/emblem.jpg" className="inner_img" alt="Lotus Valley International School, Greater Noida" /></p>
          <p>The carefully chosen school insignia encompasses different aspects of learning: the global outlook necessary for the leaders of tomorrow, the book of knowledge, the torch of hope and integrity that ignites the minds of our young people and the Veena of Saraswati, the Hindu goddess of learning and speech.</p>
          <p>The school emblem draws inspiration from the five classical elements-Earth, Water, Fire, Air and Sky. We endeavour to instil in our learners the attributes of the five elements. The lotus of transcendence flanked by two swans at the top highlights the swan's unique ability to separate milk from water just like the discerning minds of our learners.</p>
          <p>The colour blue reminds us of the flowing nature of water and motivates learners to keep on advancing towards their goals. The colour yellow brings alive the spirit of fire along with its strong will and energy while the golden colour represents the nurturing and stable quality of earth. The flags with the initials of the school are the omnipresent ambassadors of direction like the ever-present air.</p>
          <div className="clr"></div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default OurEmblem
